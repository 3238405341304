import * as React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper';
import BgImage
  from '../images/bg/thornhill-plumbing-Residential-Commercial.jpg';

const HeroImage = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: url(${BgImage}) no-repeat top left;
  background-size: cover;
  margin-bottom: 64px;
`;

const HeroImageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: white;
  background-color: rgba(0, 0, 0, .5);
`;

// const FakeHeading = styled.p`
// font-size: 2.2rem;
// text-align: center;
// `

const SubHeading = styled.h2`
  text-align: center;
  margin-top: 64px;
  margin-bottom: 32px;
`;

const AboutUs = () => (
    <Layout>
		<Seo title='About Thornhill Plumbing' description='A brief bio about us' />
		
		<HeroImage>
			<HeroImageInnerContainer>
				<h1>About<br />Thornhill Plumbing</h1>
			</HeroImageInnerContainer>
    	</HeroImage>

		<SiteWidthWrapper>
			<SubHeading>A Family Run Operation</SubHeading>
			<p>Alex Takach has been working in the plumbing and heating industry for over 20 years. It wasn’t until November 2017 that he decided to go out on his own and open, a family run business named Thornhill Plumbing.</p>
			<p>Alex has extensive experience that includes new construction, such as restaurants and hair-salons, as well as repiping older apartment buildings. Alex enjoys working closely with home owners, establishing a true rapport with his clients, and meeting the needs of the requested home improvements. Alex wants to help serve his community but most importantly, he wants to provide the best service available to the families in his community. He wants to always help keep a house a home.</p>
		</SiteWidthWrapper>
	</Layout>
);

export default AboutUs;